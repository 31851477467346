<template>
      <div class="auth-wrapper">
      <div class="auth-inner">
        <form ref="form" @submit.prevent="handleSubmit">
            <Error v-if="error" :error="error"/>
            <h3>{{ $t("profile") }}</h3>

            <b-form-group
                :label="$t('email')"
                label-for="email-input"
                :invalid-feedback="$t('email_required')"
                >
                <b-form-input readonly type="text" class="form-control" v-model="user.email" :placeholder="$t('user_email')" required/>
            </b-form-group>

            <div class="form-group">
                <label>{{ $t("timezone") }}</label>
            <b-form-select id="timeZone" name="timeZones" v-model="user.timeZone" @change="onChange($event)">
                <b-form-select-option :value="null" disabled>{{$t('select_timezone')}}</b-form-select-option>
                <b-form-select-option :value="timeZone.id" v-for="timeZone in timeZones" :key="timeZone.id">{{timeZone.displayName}} ({{timeZone.id}})</b-form-select-option>
            </b-form-select>
            </div>

            <button class="btn btn-primary btn-block">{{ $t("save") }}</button>

        </form>
      </div>
    </div>
</template>
<script>
/*<h3 v-if="action == 'add'">Add User</h3>
  <h3 v-else>Edit User</h3>
<b-button type="submit" variant="primary">Submit</b-button>*/
//<h3 v-if="!user">You are not logged in!</h3>
//<h3 v-if="user">{{count}}</h3>
//<UserList :userList="users" v-on:userSelected="onUserSelected"/>

import {mapGetters} from 'vuex'
export default {
    name: 'Profile',
    components: {
    },
    data() {
        return {
            error: null
        }
    },
    // props: [
    //     'user',
    //     'action'
    //     ],
    computed: {
        ...mapGetters(['user']),
        ...mapGetters(['timeZones']),
    },
    methods: {
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        handleSubmit() {
            if (!this.checkFormValidity()) {
                return false
            }
            
            var payload = {
                id: this.user.id,
                email: this.user.email,
                timeZone: this.user.timeZone
            }
            var method = 'update_profile'
            
            console.log('profile submit payload', payload, method)
            this.$store.dispatch(method, payload)
            .then(success => {
                console.log(success)
                alert(this.$t('profile_updated'))
                //this.$router.push("/")
            })
            .catch(error => {
                console.log(error)
                //alert(error)
                this.error = error;
                //this.error = 'Invalid username/password!'
            })
            return true
        },
        getTimeZones(){
            console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
            this.$store.dispatch("get_timezones")
            .then(success => {
                console.log('loaded timezones', success)
                /*if (this.deviceList.length >= 1) {
                    var found = false
                    if (this.selectedDeviceId != null) {
                        for (var i=0; i<this.deviceList.length; i++){
                            var device = this.deviceList[i]
                            if (device && device.sims && device.sims.length>0 && this.selectedDeviceId == device.id) {
                                found = true
                                this.$store.dispatch("set_selected_device_id", device.id)
                                history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId);
                                //this.$store.dispatch("set_selected_device", device)
                                this.getDeviceData(this.selectedDeviceId)
                                this.getActualDeviceData(this.selectedDeviceId)

                                break
                            }
                        }
                    }
                    if (!found) {
                        for (i=0; i<this.deviceList.length; i++){
                            device = this.deviceList[i]
                            if (device && device.sims && device.sims.length>0){
                                this.$store.dispatch("set_selected_device_id", device.id)
                                history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId);
                                //this.$store.dispatch("set_selected_device", device)
                                this.getDeviceData(this.selectedDeviceId)
                                this.getActualDeviceData(this.selectedDeviceId)
                                break
                            }
                        }
                    }
                    
                }
*/
            })
            .catch(error => {
                console.log('failed to load timezones', error)
                //this.$router.push('/login')
                //this.error = true;
            })
        },
        
        
        getProfile(){
            this.$store.dispatch("get_profile")
            .then(success => {
                console.log('loaded user profile', success)
                //this.$router.push("/")
                //this.$store.dispatch('set_user', {email:'',logged:true})
            })
            .catch(error => {
                console.log('failed to load user profile', error)
                this.error = error;
                //this.$router.push('/login')
                //this.error = true;
            })
        },
        onChange(event){
            console.log(event)
        }
    },
    async created() {
            this.getTimeZones()
            this.getProfile()
        }
}
</script>
